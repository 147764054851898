// normalize CSS across browsers
import './src/normalize.css';
// custom CSS styles
import './src/style.scss';
// import './src/twoslash.scss';
import 'prismjs/plugins/command-line/prism-command-line.css';
// import 'prismjs/themes/prism.css';
import './src/prism-vscode-dark-plus.scss';
// other imports

import {MDXProvider} from '@mdx-js/react';
import {WrapRootElementBrowserArgs} from 'gatsby';
import Terminal from './src/components/terminal';

export function wrapRootElement({element}: WrapRootElementBrowserArgs) {
  return (
    <MDXProvider
      components={{
        Terminal,
      }}
    >
      {element}
    </MDXProvider>
  );
}
