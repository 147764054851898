import {ReactNode} from 'react';

interface Props {
  children: ReactNode;
}

function Terminal({children}: Props) {
  return <div>{children}</div>;
}

export default Terminal;
